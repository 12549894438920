.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emoji {
  margin-top: 24px;
  font-size: 40px;
  line-height: 40px;
}

.youreAllSet {
  margin-top: 12px;
}

.flow {
  margin-top: 48px;
  width: 60%;
}

.confirmationText {
  margin-top: 48px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
}

.bold {
  font-weight: 700;
}

.buttonsWrapper {
  width: 100%;
  margin-top: 48px;
  padding-bottom: 24px;
}

.topButton,
.bottomButton {
  height: 48px;
  width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fcfcfc;
}

.bottomButton {
  margin-top: 24px;
}
