.home {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: linear-gradient(
    180deg,
    #d9d9d9 -8.11%,
    #91bbf3 -8.11%,
    #edf4fd 72.98%,
    #f5faff 111.86%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
