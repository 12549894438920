*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  font-weight: 500;
}
html,
body {
  height: 100%;
  font-family: 'Inter';
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}

input[type='radio'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0;
  margin-right: 10px;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type='radio']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  border: 0.15em solid currentColor;
  box-shadow: inset 1em 1em #2278e6;
}

input[type='radio']:checked::before {
  transform: scale(1);
}
input[type='radio']:checked {
  color: #2278e6;
  border-color: #2278e6;
}

input[type='radio'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0;
  margin-right: 10px;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type='radio']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  border: 0.15em solid currentColor;
  box-shadow: inset 1em 1em #2278e6;
}

input[type='radio']:checked::before {
  transform: scale(1);
}
input[type='radio']:checked {
  color: #2278e6;
  border-color: #2278e6;
}

input {
  color: #153260;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

button {
  background-color: transparent;
  border-width: 0;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 0;
}
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
a {
  margin-top: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 133%;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #2278e6;
}

.text-sm {
  font-size: 12px !important ;
}
.text-regular {
  font-size: 16px !important;
}

.color-red {
  color: #e5446d;
}

.loaderWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 72px;

  p {
    margin-top: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 133%;
    color: #5a99ed;
  }
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}
.loader {
  color: #91bbf3;
  font-size: 4px;
  text-indent: -99999em;
  margin: 24px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before,
.loader:after {
  position: absolute;
  content: '';
}
.loader:before {
  width: 5.2em;
  height: 10.2em;
  background: #fff;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.1em 5.1em;
  transform-origin: 5.1em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.loader:after {
  width: 5.2em;
  height: 10.2em;
  background: #fff;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 4.9em;
  -webkit-transform-origin: 0.1em 5.1em;
  transform-origin: 0.1em 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#root {
  isolation: isolate;
}

@media (max-width: 768px) {
  a {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 133%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2278e6;
  }
}
