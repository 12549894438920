.container {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start;
  max-width: 100%;
  width: 340px;
  height: 70vh;
}

.titleWrapper {
  padding-top: 16px;
}

.title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #153260;
}

.subtitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #333f4c;
  margin-top: 16px;
}

.imagesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 16px;
}

.yearContainer {
  margin-bottom: 30px;
}

.imgContainer {
  margin-bottom: 8px;
}

.imgContainer > label {
  font-size: 12px;
  text-align: center;
}

.imgDescription {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  font-family: 'Inter';
  margin-top: 8px;
}

.media {
  width: 160px;
  height: 120px;
  border-radius: 10px;
}

details > summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #333f4c;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  align-items: center;
  border: none;
  cursor: pointer;
  list-style: none;
}

details > div {
  overflow-y: scroll;
}

.arrow {
  color: #7f8790;
  width: 20px;
  height: 20px;
}

.divider {
  margin-top: 16px;
  margin-bottom: 16px;
  color: #eaebeb;
}

.yearHeader {
  font-family: 'Inter';
  font-weight: 500;
  line-height: 32px;
  font-size: 24px;
  color: #333f4c;
}

.line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #333f4c;
}

.carousel {
  display: flex;
  flex-direction: row;
}

.spinner {
  color: #2278e6;
  margin-top: 50%;
}

.rotateRightArrow {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.backText {
  display: flex;
  flex-direction: row;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
  color: #153260;
}

details summary::-webkit-details-marker {
  display: none;
}

@media only screen and (max-width: 720px) {
  .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #153260;
  }

  .subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;

    color: #333f4c;
    margin-top: 16px;
  }

  .yearHeader {
    font-family: 'Inter';
    font-weight: 500;
    line-height: 28px;
    font-size: 20px;
    color: #333f4c;
  }

  details > summary {
    color: #333f4c;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
}

.detailCarousel {
  margin-top: 24px;
}

@media only screen and (max-width: 600px) {
  .container {
    width: 300px;
    list-style: none;
  }

  details {
    list-style: none;
  }

  .media {
    width: 140px;
    height: 100px;
    border-radius: 10px;
  }

  .detailCarousel {
    margin-top: 24px;
    margin-right: -20px;
    margin-left: -20px;
  }
}
