.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 0px;
  gap: 10px;
  width: 100%;
  height: 48px;
  border-radius: 16px;
  box-shadow: 0px 7px 20px -10px rgba(77, 102, 220, 0.16);
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
  transition: all 0.5s;
}

.main {
  background: #2278e6;

  &:hover {
    filter: opacity(0.8);
  }

  &:active {
    box-shadow: 3px 7px rgba(77, 102, 220, 0.16);
    transform: translateY(2px);
  }

  &:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: default;
  }

  .text {
    height: 24px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}

.secondary {
  background: #fcfcfc;
  border: 1px solid #2278e6;

  &:hover {
    filter: opacity(0.8);
  }

  &:active {
    box-shadow: 3px 7px rgba(77, 102, 220, 0.16);
    transform: translateY(2px);
  }

  .text {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #2278e6;
  }
}
