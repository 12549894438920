.container {
  width: 100%;
  padding: 24px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.weekSelector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  img {
    cursor: pointer;
  }
  div {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #123d6e;
  }
}

.timeSlotsCard {
  max-height: 64vh;
  overflow-y: auto;
  // we have to make the width a little wider to accomodate the default scroll bar without causing the slots to wrap
  width: 104%;
  overflow-y: scroll;

  & > div {
    margin-top: 16px;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  h1 {
    height: 20px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #606a76;
    margin: 0px 0px 12px 0px;
  }
}

.timeSlotGrid {
  display: flex;
  flex-flow: row wrap;
  gap: 12px;
  /*   justify-content: space-between;
  padding: 0 12px; */
}

@media (max-height: 668px) {
  .timeSlotsCard {
    padding-bottom: 24px;
  }
}
