.container {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  isolation: isolate;
  background: #f5faff;
  border-radius: 24px;
  width: 100%;
  max-width: 400px;

  a {
    margin: 0;
    cursor: pointer;
  }

  img,
  a {
    width: 100%;
  }

  div {
    padding: 24px;
    width: 100%;
    text-align: center;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #153260;
      margin-bottom: 0px;
    }
  }
}

@media (max-height: 668px) {
  .container {
    img,
    a {
      height: 100%;
      object-fit: cover;
      border-bottom-right-radius: 24px;
      border-bottom-left-radius: 24px;
    }
  }
}
