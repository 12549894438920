* {
  box-sizing: border-box;
}

body,
html {
  background-color: #f6f9fc;
  font-size: 18px;
}

h1,
h2 {
  color: #32325d;
  font-weight: 400;
  line-height: 50px;
  font-size: 40px;
  margin: 20px 0;
  padding: 0;
}

label {
  color: #333f4c;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
}

.mainContent {
  margin-top: 40px;
  width: 320px;
}

.cardContainer {
  display: flex;
  flex-direction: column;
}

.cardElement {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cardExtra {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cardExtraElement {
  width: 150px;
}

.input,
.StripeElement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: 'Inter';
  font-style: normal;
  border-radius: 16px;
  background: #edf4fd;
  color: #7f8790;
  height: 48px;
}

.StripeElement,
.StripeInput {
  padding-left: 24px;
}

input:focus,
.StripeElement--focus {
  outline: 1px solid #2278e6;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.image {
  margin-right: 10px;
}

.close {
  margin-top: -16px;

  color: #7f8790;
  font-size: 18px;
}

.error {
  color: #a42c50;
  font-size: 12px;
}

.paymentButton {
  margin-top: '64px';
}
