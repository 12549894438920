.container {
  margin-top: 16px;

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #333f4c;
  }
}
.options {
  width: 223px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.errorMessage {
  color: #ad343e;
  font-weight: 500;
  font-size: 12px;
}
