.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 24px;
}

.notificationContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 16px;
  align-items: center;
  background-color: #edf4fd;
  border-radius: 16px;
}

.notificationText {
  color: #333f4c;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-left: 10px;
}

.logosContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.insuranceSection {
  background: #fcfcfc;
}

.insuranceImg {
  width: 70%;
}

.logoWrapper {
  cursor: pointer;
  width: 148px;
  height: 100px;
  border: 1px solid #eaebeb;
  background-color: #f7f7f8;
  border-radius: 24px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */

  &:hover,
  &.selected {
    border: 1px solid #91bbf3;
    background-color: #cbddf9;
    filter: none;
    -webkit-filter: none;
  }

  img {
    object-fit: contain;
  }
}

@media (max-height: 720px) {
  .container {
    margin-bottom: 160px;
  }
}
