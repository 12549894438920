.container {
  margin-bottom: 12px;
  width: 100%;
}

.input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 16px;
  gap: 10px;
  width: 100%;
  background: #f4f8fd;
  border-radius: 16px;
  border: none;
  flex: none;
  order: 1;
  flex-grow: 0;
  box-sizing: border-box;
  height: 48px;
  margin-top: 4px;
  font-size: 14px;
}

.input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #7f8790;
}

.errorMessage {
  color: #ad343e;
  margin-top: 4px;
  font-weight: 500;
  font-size: 12px;
  padding-left: 12px;
  padding-left: 12px;
}

@media (max-width: 768px) {
  .container {
    align-items: center;
  }
  .input {
    height: 56px;
  }
}
