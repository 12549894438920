.container {
  position: absolute;
  top: calc(50% - 165px);
  left: calc(50% - 165px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 40px;
  width: 330px;
  height: 330px;
  background: #fcfcfc;
  backdrop-filter: blur(22px);
  border-radius: 32px;
  border: none;
  outline: none;

  h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    font-feature-settings: 'case' on;
    color: #a42c50;
  }
  .message {
    margin-top: 16px;
    margin-bottom: 32px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #153260;
  }
}
