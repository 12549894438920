.container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #eaebeb;
  border-radius: 8px;

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #606a76;
    margin: 0;
  }
}

.selectedContainer {
  background: #cbddf9;
  h2 {
    color: #123d6e;
  }
}
