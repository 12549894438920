.container {
  margin-bottom: 12px;
  width: 100%;
  position: relative;
}

.inputContainer {
  position: relative;

  img {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
}

.input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 40px 16px 16px;
  gap: 10px;
  width: 100%;
  background: #f4f8fd;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  border: none;
  flex: none;
  order: 1;
  flex-grow: 0;
  box-sizing: border-box;
  height: 48px;
  margin-top: 4px;
  font-size: 16px;
  transform: translateZ(0);
}

input:focus {
  border-radius: 16px;
  -webkit-border-radius: 16px;
}

/* force the focus styles in Safari and Firefox */
.input:focus::-webkit-input-placeholder {
  border-radius: 16px;
  -webkit-border-radius: 16px;
}

.input:focus:-moz-placeholder {
  border-radius: 16px;
  -webkit-border-radius: 16px;
}

.calendarIcon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  height: 16px;
  width: 16px;
}

input[type='date']::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url('../../../assets/calendar-gray.svg') no-repeat;
  width: 16px;
  height: 16px;
  border-width: thin;
  transform: translateX(4px);
}

.input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #7f8790;
}

.errorMessage {
  color: #ad343e;
  margin-top: 4px;
  font-weight: 500;
  font-size: 12px;
  padding-left: 12px;
  padding-left: 12px;
}

@media (max-width: 768px) {
  .container {
    align-items: center;
  }
}
