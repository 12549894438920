.container {
  background: #ffffff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: column;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  left: 0;
}

.sticky {
  background-color: #ffffff;
  height: 56px;
  width: 100%;
}

.menu {
  height: 56px;
  padding: 8px 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .phoneNumber {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 143%;
    text-align: right;
    color: #153260;

    a {
      margin: 0;
      display: inline;
      text-decoration: none;
    }
  }

  .logo {
    height: 20px;
  }

  .smsIcon,
  .iconButton,
  p {
    width: 150px;
  }
  .iconButton {
    text-align: left;
    display: flex;
    align-items: center;
  }
  .smsIcon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: right !important;
    width: 24px;
    height: 24px;
    margin-top: 4px;
  }

  .backButton {
    width: 16px;
    height: 16px;
  }

  .smsIcon > img {
    width: 24px;
    height: 24px;
  }
}

.backButton {
  cursor: pointer;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}

.notificationWrapper {
  width: 320px;
  display: flex;
  flex-direction: row;
  padding: 16px;
  align-items: center;
  background-color: #edf4fd;
  border-radius: 16px;

  margin-bottom: 32px;
}

.notificationText {
  color: #333f4c;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-left: 10px;
  text-align: left;
}

.closeIcon {
  margin-left: auto;
  margin-right: 0;
  align-self: flex-start;
  cursor: pointer;
}

.title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 24px !important;
  line-height: 32px;
  text-align: center;
  color: #153260;
}

.subTitle {
  margin-top: 8px;
  color: #7f8790;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.progressIndicatorWrapper {
  padding: 0;
  width: 100%;
  height: 2px;
  background-color: #e9f2fb;
  position: relative;
}

.progressIndicator {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #2278e6;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
}

.secondaryButton {
  margin-top: 14px;
  width: 100%;
}

@media (max-width: 768px) {
  .contentWrapper {
    width: 100%;
  }

  .menu {
    .smsIcon,
    .iconButton {
      width: 20px;
      height: 20px;
    }

    .smsIcon > img {
      width: 24px;
      height: 24px;
    }
  }
}

@media (max-height: 720px) {
  .buttonWrapper {
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 998;
  }

  .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px !important;
    line-height: 28px;
    text-align: center;
    color: #153260;
  }
}
