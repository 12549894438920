.container {
  padding: 24px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.message {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #153260;
  margin-bottom: 48px;
  text-align: center;
}

.error {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ad343e;
  margin-top: 15px;
}

@media (max-height: 668px) {
  .message {
    margin-bottom: 24px;
  }
}
