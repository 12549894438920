.datePicker {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 40px 16px 16px;
  gap: 10px;
  width: 100%;
  background: #f4f8fd;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  border: none;
  flex: none;
  order: 1;
  flex-grow: 0;
  box-sizing: border-box;
  height: 48px;
  margin-top: 4px;
  font-size: 16px;
  transform: translateZ(0);
}

.datePicker::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #7f8790;
}

:global {
  .react-datepicker__header {
    background-color: #f4f8fc !important;
    font-family: 'Inter';
  }
}
