.container {
  cursor: pointer;
  width: 360px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  background-color: #fcfcfc;
  border-radius: 16px;
  margin-bottom: 24px;
  border: 1px solid #f7f7f8;

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 32px;
  }

  h2 {
    font-family: 'Montserrat';
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #2278e6;
    margin: 0px !important;
  }

  .description {
    margin-bottom: 16px;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #606a76;
  }

  .price {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #333f4c;
  }

  .priceVerticalSeparator {
    font-size: 18px;
    margin: 0 2px;
  }
}

.emergency {
  color: #a42c50 !important;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    max-width: 400px;
  }
}

@media (max-height: 668px) {
  .container {
    height: auto;
  }
}
