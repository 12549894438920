.formContainer {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
}

.message {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #153260;
  margin-bottom: 48px;
  text-align: center;
}

.label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-top: 6px;
  margin-bottom: 6px;
  color: #333f4c;
}

.labelDescription {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  margin-top: 4px;
  margin-bottom: 8px;
  color: #7f8790;
}

.error {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ad343e;
  margin-top: 15px;
  overflow-wrap: break-word;
}

.success {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: green;
  margin-top: 15px;
  height: 100px;
  overflow-y: scroll;
}
